/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

const isStorageAvailable = function (storage) {
  if (typeof storage == 'undefined') {
    return false;
  }

  try { // hack for safari incognito
    storage.setItem('storage', '');
    storage.getItem('storage');
    storage.removeItem('storage');
    return true;
  } catch (err) {
    return false;
  }
};

const localStorageAvailable = isStorageAvailable(window.localStorage),
  sessionStorageAvailable = isStorageAvailable(window.sessionStorage);

if (!localStorageAvailable || !sessionStorageAvailable) {
  class Storage {
    type: string;
    length: 0;

    // initialise if there's already data
    data = this.getData();

    constructor(type) {
      this.type = type;
    }

    private createCookie(name, value, days) {
      let date, expires;

      if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
      } else {
        expires = '';
      }
      document.cookie = name + '=' + value + expires + '; path=/';
    }

    private readCookie(name) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      let i, c;

      for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }

    private setData(data) {
      data = JSON.stringify(data);
      if (this.type == 'session') {
        window.name = data;
      } else {
        this.createCookie('localStorage', data, 365);
      }
    }

    private clearData() {
      if (this.type == 'session') {
        window.name = '';
      } else {
        this.createCookie('localStorage', '', 365);
      }
    }

    private getData() {
      const data = this.type == 'session' ? window.name : this.readCookie('localStorage');
      return data ? JSON.parse(data) : {};
    }

    clear() {
      this.data = {};
      this.length = 0;
      this.clearData();
    }

    getItem(key: string) {
      return this.data[key] === undefined ? null : this.data[key];
    }

    key(i: any) {
      // not perfect, but works
      let ctr = 0;
      for (let k in this.data) {
        if (ctr == i) {
          return k
        } else {
          ctr++;
        }
      }
      return null;
    }

    removeItem(key: string) {
      if (this.data[key] === undefined) {
        this.length--;
      }
      delete this.data[key];
      this.setData(this.data);
    }

    setItem(key, value) {
      if (this.data[key] === undefined) {
        this.length++;
      }
      this.data[key] = value + ''; // forces the value to a string
      this.setData(this.data);
    }
  }

  if (!localStorageAvailable) {
    window.localStorage.__proto__ = new Storage('local');
  }

  if (!sessionStorageAvailable) {
    window.sessionStorage.__proto__ = new Storage('session');
  }

}

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.


/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';



/** ALL Firefox browsers require the following to support `@angular/animation`. **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent mappers.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = isNaN(targetLength) ? 0 : targetLength; // truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (this.length >= targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}
